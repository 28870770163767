import * as React from "react"
import { Link, graphql } from "gatsby"
import './blog.css';

// styles
const pageStyles = {
  color: "#232129",
  padding: "25px",
  maxWidth: "550px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 34,
  maxWidth: 320,
}

const dateStyles = {
    marginBottom: 34,
    fontStyle: "italic",
    color: "#9D9D9D"
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

export default function BlogPostTemplate({
    data, // this prop will be injected by the GraphQL query below.
  }) {

    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
  return (
    <main style={pageStyles}>
      <h1 style={headingStyles}>{frontmatter.title}</h1>
      <p style={dateStyles}>{frontmatter.date}</p>
      <div style={paragraphStyles}
            dangerouslySetInnerHTML={{ __html: html }} />
      <br />
      <br />
      <Link to="/blog">Go back</Link>

    </main>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
  `

